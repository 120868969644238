import { useState } from "react";
import axios from "axios";
import countriesList from "../data/countries.json";
import ReactGA from "react-ga4";

const initialState = {
  first_name: "",
  email: "",
  message: "",
};
export const Contact = () => {
  const [contactInfo, setContactInfo] = useState(initialState);
  const [showMessage, setshowMessage] = useState({ type: null, message: null, hide: true, submitButtonMsg: null });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setContactInfo((prevState) => ({ ...prevState, [name]: checked }));
    } else {
      setContactInfo((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    ReactGA.event({
      category: "User",
      action: "Sent a message through the contact form",
    });

    send_notification(contactInfo);

    call_backend(contactInfo)
      .then((res) => {
        if (res.status === 200) {
          setshowMessage({
            type: "alert alert-success",
            message: `Thank you for contacting us! Your message ID is: '${res.data.contact_id}'`,
            hide: false,
            submitButtonMsg: "Message Sent!",
          });
        } else {
          setshowMessage({
            type: "alert alert-danger",
            message: "Something went wrong. Please try again later...",
            hide: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const listOfCountries = () => {
    let list = [];

    countriesList.map((v) => {
      list.push(
        <option style={{ color: "lightgray" }} value={v.name} disabled={v.code === "default" ? true : false} selected={v.code === "default" ? true : false}>
          {v.name}
        </option>
      );
      return null;
    });

    return list;
  };

  return (
    <>
      <div
        className="page contactpage"
        style={{
          marginTop: "0px",
        }}
      >
        {/* SECTION - page-underlay will act as the width 1170px container to keep the contents inside. */}
        <div
          className="page-underlay"
          style={{
            padding: "0px !important",
          }}
        >
          <div id="contact" style={{ color: "black", background: "none" }}>
            <div className="container">
              <div className="col-md-8">
                <div className="row">
                  <div className="section-title">
                    <h1 className="headertext" style={{ color: "rgb(2, 117, 133)" }}>
                      Contact Us
                    </h1>
                    <br />
                    <p className="ptext">Please fill out the form below to send us an email and we will get back to you as soon as possible.</p>
                  </div>
                  <form name="sentMessage" style={{ padding: "0px !important" }} validate onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group required">
                          <input
                            type="text"
                            id="first_name"
                            name="first_name"
                            className="form-control contactUsControl"
                            placeholder="First Name"
                            required
                            onChange={handleChange}
                          />
                          <p className="help-block text-danger"></p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input type="text" id="last_name" name="last_name" className="form-control contactUsControl" placeholder="Surname" required onChange={handleChange} />
                          <p className="help-block text-danger"></p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <select
                            className="form-select form-control contactUsControl"
                            aria-label="Default select example"
                            id="type"
                            name="type"
                            required
                            onChange={handleChange}
                            style={{
                              color: "gray",
                            }}
                          >
                            <option selected disabled>
                              I am a...
                            </option>
                            <option value="Business">Business</option>
                            <option value="Academic">Academic</option>
                            <option value="Government">Government</option>
                            <option value="Student">Student</option>
                          </select>
                          <p className="help-block text-danger"></p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <select
                            className="form-select form-control contactUsControl"
                            aria-label="Default select example"
                            id="country"
                            key={"country-selection"}
                            name="country"
                            placeholder="Country"
                            required
                            onChange={handleChange}
                            style={{
                              color: "gray",
                            }}
                          >
                            {listOfCountries()}
                          </select>
                          <p className="help-block text-danger"></p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input type="text" id="organisation" name="organisation" className="form-control contactUsControl" placeholder="Organisation" onChange={handleChange} />
                          <p className="help-block text-danger"></p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input type="text" id="job_title" name="job_title" className="form-control contactUsControl" placeholder="Job Title" onChange={handleChange} />
                          <p className="help-block text-danger"></p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          {/* <input
                            type="text"
                            id="phone"
                            name="phone"
                            className="form-control contactUsControl"
                            placeholder="Phone Number (Including Area Code)"
                            onChange={handleChange}
                          /> */}

                          {/* List of sources where they heard of us */}
                          <select
                            className="form-select form-control contactUsControl"
                            aria-label="Default select example"
                            id="source"
                            name="source"
                            required
                            onChange={handleChange}
                            style={{
                              color: "gray",
                            }}
                          >
                            <option selected disabled>
                              How did you hear about us?
                            </option>
                            <option value="Google">Google</option>
                            <option value="LinkedIn">LinkedIn</option>
                            <option value="Social Media">Social Media</option>
                            <option value="Webinar">Webinar</option>
                            <option value="Event">Event</option>
                            <option value="Referral">Referral</option>
                            <option value="Other">Other</option>
                          </select>
                          <p className="help-block text-danger"></p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input type="email" id="email" name="email" className="form-control contactUsControl" placeholder="Email" required onChange={handleChange} />
                          <p className="help-block text-danger"></p>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <textarea name="message" id="message" className="form-control contactUsControl" rows="4" placeholder="Message" required onChange={handleChange}></textarea>
                      <p className="help-block text-danger"></p>
                    </div>
                    <br />
                    <div class="form-check">
                      <input
                        id="readTermsAndConditions"
                        name="readTermsAndConditions"
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        onChange={handleChange}
                        style={{ marginLeft: "10px" }}
                        required
                      />
                      <label class="form-check-label ptext" for="readTermsAndConditions">
                        I have read and I agree with the terms and conditions
                      </label>
                    </div>
                    <br />
                    <div class="form-check">
                      <input id="subscribe" name="subscribe" class="form-check-input" type="checkbox" value="" onChange={handleChange} style={{ marginLeft: "10px" }} />
                      <label class="form-check-label ptext" for="subscribe">
                        I would like to subscribe to news and emails from Circuland
                      </label>
                    </div>
                    <div id="success"></div>
                    <br />
                    <div className={showMessage.type} hidden={showMessage.hide} style={{ margin: "0px !important", marginTop: "30px" }} role="alert">
                      {showMessage.message}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "end",
                        width: "100%",
                      }}
                    >
                      <button
                        type="submit"
                        className="home-circuland-button"
                        style={{
                          marginTop: "50px",
                          size: "16pt",
                          background: showMessage.submitButtonMsg === "Message Sent" ? "radial-gradient(50, #90EE90, #008000)" : "",
                        }}
                        disabled={showMessage.type === "alert alert-success"}
                      >
                        {showMessage.submitButtonMsg ? showMessage.submitButtonMsg : "Send Message"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-3 col-md-offset-1 contact-info">
                <div className="contact-item ptext">
                  <p>
                    <span style={{ color: "rgb(2, 117, 133)" }}>
                      <i className="fa fa-map-marker" style={{ color: "rgb(2, 117, 133)" }}></i> <b>Address</b>
                    </span>
                    Circuland Ltd, <br />
                    71-75 Shelton Street,
                    <br /> Covent Garden,
                    <br /> London,
                    <br /> United Kingdom,
                    <br /> WC2H 9JQ
                  </p>
                </div>
                <div className="contact-item">
                  <p className="ptext">
                    <span style={{ color: "rgb(2, 117, 133)" }}>
                      <i className="fa fa-envelope-o" style={{ color: "rgb(2, 117, 133)" }}></i> <b> Email </b>
                    </span>{" "}
                    contactus@circuland.co.uk
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer" style={{ backgroundColor: "rgb(2, 117, 133)", color: "white", width: "100%" }}>
        <div className="container text-center">
          <p className="ptext" style={{ color: "white" }}>
            &#169; Circuland 2022 - 2024 All Rights Reserved.
            <br />
            CIRCULAND LTD Company number 14353001
            <br />
            <a href="/privacyPolicy" className="ptext" style={{ color: "white", marginRight: "10px" }}>
              <b> Privacy Policy Link</b>
            </a>
            |
            <a href="/termsAndConditions" className="ptext" style={{ color: "white", marginLeft: "10px" }}>
              <b> Terms and Conditions Link</b>
            </a>
            <br />
            V1.3.1
          </p>
        </div>
      </div>
    </>
  );
};

const call_backend = async (payload) => {
  const config = {
    method: "POST",
    url: `${process.env.REACT_APP_CIRCULAND_WEBSITE_CONTACTUS_API}`,
    headers: {
      "x-api-key": `${process.env.REACT_APP_CIRCULAND_WEBSITE_API_KEY}`,
    },
    data: {
      ...payload,
    },
  };

  return axios(config)
    .then(function (response) {
      ReactGA.event({
        category: "User",
        action: "Sent message",
      });
      return response;
    })
    .catch(function (error) {
      return error;
    });
};

const send_notification = async (payload) => {
  const config = {
    method: "POST",
    url: `${process.env.REACT_APP_AZURE_API}`,
    headers: {
      "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_AZURE_API_KEY}`,
    },
    data: {
      ...payload,
    },
  };

  return axios(config);
};
