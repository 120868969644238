export default function PrivacyPolicy() {
  const cookiePolicy = () => {
    function deleteAllCookies() {
      const cookies = document.cookie.split(";");

      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
      }

      window.location.reload();
    }

    return (
      <>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginBottom: "7.5pt",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 24,
              fontFamily: '"Tahoma",sans-serif',
              color: "#677788",
            }}
          >
            Cookie policy
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginBottom: "7.5pt",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#677788",
            }}
          >
            Our website places cookies, which are small data files, on your computer or mobile device. This is a common practice
            for all websites. Cookies help us provide you with a good experience when you browse our site and allow us to improve
            our site. Cookies cannot be used to identify you personally.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginBottom: "7.5pt",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => deleteAllCookies()}
          >
            You can consent to accept cookies the first time you visit our website and can also update your preference{" "}
            <span style={{ textDecoration: "unde" }}>here</span>.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginBottom: "7.5pt",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#677788",
            }}
          >
            Please note that it is also possible to disable cookies being stored on your computer by changing your browser
            settings, however our website and webapp may not function properly and/or some features may not be available to you in
            that case.
          </span>
        </p>
        {/* Google Analytics */}
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginBottom: "7.5pt",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 17,
              fontFamily: '"Tahoma",sans-serif',
              color: "#677788",
            }}
          >
            Google Analytics Usage
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginBottom: "7.5pt",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#677788",
            }}
          >
            To continually improve and optimize our website, Circuland utilizes Google Analytics, a web analytics service provided
            by Google, Inc. Google Analytics employs cookies to analyze how users interact with our site, including tracking user
            movements, pages visited, and overall website engagement. This information helps us understand user behavior, enhance
            our web offerings, and tailor content to better suit our audience's needs. The data gathered by Google Analytics is
            processed in accordance with Google's privacy policy. We respect your privacy and assure you that this data is used
            solely for the purpose of improving user experience on our site. By using our website (and accepting the usage of
            cookies on your initial visit), you consent to the processing of data about you by Google in the manner and for the
            purposes set out above.
          </span>
        </p>
        {/* Microsoft Clarity */}
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginBottom: "7.5pt",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 17,
              fontFamily: '"Tahoma",sans-serif',
              color: "#677788",
            }}
          >
            Microsoft Clarity and Data Usage
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginBottom: "7.5pt",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#677788",
            }}
          >
            In our continuous effort to improve Circuland’s Website and Webapp, we have integrated Microsoft Clarity. This tool
            helps us analyze how users interact with our site through the collection of behavioural metrics, heatmaps, and session
            replays. The purpose of this data collection is to enhance user experience, website functionality, and to inform our
            marketing strategies. Microsoft Clarity uses both first and third-party cookies along with other tracking technologies
            to gather this data. We assure you that all information is collected and processed in adherence to our privacy
            standards and Microsoft's privacy guidelines. For a more detailed understanding of how Microsoft uses and protects
            your data, we encourage you to read the Microsoft Privacy Statement. By using our website (and accepting the usage of
            cookies on your initial visit), you consent to this data collection as per the terms outlined in our Privacy Policy.
          </span>
        </p>
      </>
    );
  };

  const policyText = () => {
    return (
      <>
        <p>
          <span
            style={{
              fontSize: 24,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            Circuland Privacy policy & Cookie Policy
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginTop: "7.5pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "0cm",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 18,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            Definitions
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginTop: "7.5pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "0cm",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            Parties
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginBottom: "7.5pt",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            "You" and "Your" refer to you, as a User or Third Party of the Site. A "User" is an individual who accesses, browses,
            crawls, scrapes, or in any way uses the Site. A "Third Party" is an individual and/or organisation who accesses,
            browses, crawls, scrapes, or in any way uses the Site. "We", "Us", and "Our" refer to Circuland Ltd.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginTop: "7.5pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "0cm",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            Site
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginBottom: "7.5pt",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            Unless otherwise specified, the terms "Site", "Website", "App", "WebApp" refer to the pages hosted by Circuland Ltd at
            www.circuland.co.uk and subdomains, and "Circuland" refers to the infrastructure of the Site, the services provided by
            Circuland Ltd, primarily through the Site, and the Circuland brand.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginTop: "7.5pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "0cm",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 18,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            Introduction
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginBottom: "7.5pt",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            We are a Materials Passport Platform that offers a wide range of services and activities. Our primary purpose is to
            create a National Database that will store and democratise Material Passport information.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginBottom: "7.5pt",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            We adhere to terms set out in the Data Protection Act 2018, the UK’s implementation of General Data Protection
            Regulation (GDPR).
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginBottom: "7.5pt",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            Before submitting your personal information to our site, please read this Policy carefully to learn about our privacy
            practices.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginTop: "7.5pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "0cm",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 18,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            Information we collect from you
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginBottom: "7.5pt",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            We receive and store any information you enter on our site or give us in any other way. This includes information that
            can identify you ("personal information"), including your first and last name, photograph, job title, organisation,
            country of residence and mobile telephone number.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginTop: "7.5pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "0cm",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 18,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            Information from other sources
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginBottom: "7.5pt",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            We automatically collect some information about your computer or mobile phone when you visit the Circuland Site. For
            example, we will collect session data, including your IP address, Web browser software, and referring Website. We may
            also collect information about your online activity, such as content viewed, and pages visited with the you of
            tracking analytics.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginTop: "7.5pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "0cm",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 18,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            How we use your information
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginBottom: "7.5pt",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            Circuland uses the information we collect about you for the following general purposes:
          </span>
        </p>
        <ul style={{ listStyleType: "disc" }}>
          <li>
            <span
              style={{
                fontFamily: '"Tahoma",sans-serif',
                fontSize: "10.5pt",
                color: "#7F7F7F",
              }}
            >
              For registration and to manage your account, including to facilitate your access to, and use of, our site; to
              communicate with you in general;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Tahoma",sans-serif',
                fontSize: "10.5pt",
                color: "#7F7F7F",
              }}
            >
              Identity checking and validation of your credentials with third parties including the GLA;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Tahoma",sans-serif',
                fontSize: "10.5pt",
                color: "#7F7F7F",
              }}
            >
              Publish certain information enabling creation of Material Passports which is searchable/accessible by the public and
              Circuland users;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Tahoma",sans-serif',
                fontSize: "10.5pt",
                color: "#7F7F7F",
              }}
            >
              Share aggragated results of Material Passports referencing reports to the end users;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Tahoma",sans-serif',
                fontSize: "10.5pt",
                color: "#7F7F7F",
              }}
            >
              To respond to your questions and comments;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Tahoma",sans-serif',
                fontSize: "10.5pt",
                color: "#7F7F7F",
              }}
            >
              To measure interest in and improve our products, services, and site;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Tahoma",sans-serif',
                fontSize: "10.5pt",
                color: "#7F7F7F",
              }}
            >
              To notify you about services available from us or our partners that may be of interest to you;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Tahoma",sans-serif',
                fontSize: "10.5pt",
                color: "#7F7F7F",
              }}
            >
              To otherwise customise your experience with Circuland;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Tahoma",sans-serif',
                fontSize: "10.5pt",
                color: "#7F7F7F",
              }}
            >
              To solicit information from you, including through surveys;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Tahoma",sans-serif',
                fontSize: "10.5pt",
                color: "#7F7F7F",
              }}
            >
              To resolve disputes or troubleshoot problems;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Tahoma",sans-serif',
                fontSize: "10.5pt",
                color: "#7F7F7F",
              }}
            >
              To prevent potentially prohibited or illegal activities;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Tahoma",sans-serif',
                fontSize: "10.5pt",
                color: "#7F7F7F",
              }}
            >
              To enforce our Terms &amp; Conditions; and
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Tahoma",sans-serif',
                fontSize: "10.5pt",
                color: "#7F7F7F",
              }}
            >
              As otherwise described to you at the point of collection.
            </span>
          </li>
        </ul>
        <p>
          <span
            style={{
              fontSize: 18,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          />
          <span
            style={{
              fontSize: 18,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            <br />
            <br />
          </span>
        </p>
        <p>
          <span
            style={{
              fontSize: 18,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            Email Communications
          </span>
        </p>
        <p>
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            We want to make it easy for you to take advantage of the opportunities on our site. One way we may do this is by
            sending you email messages that contain information about updates and notifications.
          </span>
        </p>
        <p>
          <span
            style={{
              fontSize: 18,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            How you can access your information
          </span>
        </p>
        <p>
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            We endeavour to ensure that your information is relevant to its intended use, accurate, and complete. Your personal
            information can be accessed using the User section of the Circuland Webapp.
          </span>
        </p>
        <p>
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            You can close your Circuland account and have your Personal Information deleted by contacting us at the email address
            listed below. We will send you an e-mail to confirm your request. Please also note that we may retain certain
            information associated with your account in our archives, for reasons including analytical purposes and to ensure the
            integrity of our record keeping.
          </span>
        </p>
        <p>
          <span
            style={{
              fontSize: 18,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            How you can update your information
          </span>
        </p>
        <p>
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            Your information can be updated within the User section of our Webapp. Options include a changing your mobile number,
            editing your ongoing Material Passports, Creating new Material, Viewing Published Material Passports and other
            activities that are in relation to Material Passports.
          </span>
        </p>
        <p>
          <span
            style={{
              fontSize: 18,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            Your choices with respect to the collection and use of your information
          </span>
        </p>
        <ul style={{ listStyleType: "disc" }}>
          <li>
            <span
              style={{
                fontFamily: '"Tahoma",sans-serif',
                fontSize: "10.5pt",
                color: "#7F7F7F",
              }}
            >
              You can choose not to provide us with any information, although it may be needed to take advantage of certain
              features offered.
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Tahoma",sans-serif',
                fontSize: "10.5pt",
                color: "#7F7F7F",
              }}
            >
              You also can add or update information and close your account as described above.
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Tahoma",sans-serif',
                fontSize: "10.5pt",
                color: "#7F7F7F",
              }}
            >
              You will be given the opportunity to unsubscribe from promotional messages in any such e-mail we send. Please note
              that we reserve the right to send you other communications, including service announcements and administrative
              messages relating either to your Circuland account, without offering you the opportunity to opt out of receiving
              them.
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Tahoma",sans-serif',
                fontSize: "10.5pt",
                color: "#7F7F7F",
              }}
            >
              You can withdraw consent that was previously granted by contacting us at the email address listed below.
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Tahoma",sans-serif',
                fontSize: "10.5pt",
                color: "#7F7F7F",
              }}
            >
              In accordance with the Data Protection Act 2018 you have the right to access any information that we hold relating
              to you. Please write to us at the email below for a copy of your information.
            </span>
          </li>
        </ul>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginTop: "7.5pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "0cm",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 18,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            How we protect your information
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginBottom: "7.5pt",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            We want you to feel confident about using Circuland’s Website and Webapp, and we are committed to protecting the
            information we collect. While no website can guarantee security, we have implemented appropriate administrative,
            technical, and physical security procedures to help protect the personal information you provide to us. For example,
            only authorised employees are permitted to access personal information, and they only may do so for permitted business
            functions. In addition, we use whole-site SSL encryption when transmitting your personal information between your
            system and ours, and we employ firewalls and intrusion detection systems to help prevent unauthorised persons from
            gaining access to your information.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginTop: "7.5pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "0cm",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 18,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            Children's privacy
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginBottom: "7.5pt",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            Circuland does not offer services directed to children. Should an individual whom we know to be a child under age 13
            send personal information to us, we will delete or destroy such information as soon as reasonably possible.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginTop: "7.5pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "0cm",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 18,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            External links
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginBottom: "7.5pt",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            The Circuland site may contain links to external websites not operated by Circuland Ltd. We recommend that you read
            these sites' own Privacy Policies to understand their procedures for collecting, using, and disclosing personal
            information.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginTop: "7.5pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "0cm",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 18,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            Changes to this Privacy Policy
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginBottom: "7.5pt",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            Circuland may update this Privacy Policy in the future. We will endeavour to notify our members about material changes
            to this Privacy Policy by either sending a notice to the email address you provided to us or by placing a prominent
            notice on our website or Circuland webapp.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginTop: "7.5pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "0cm",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 18,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            How you can contact us or make a complaint
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginBottom: "7.5pt",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            If you have questions or complaints about this Privacy Policy, how we use your data or would like to make a complaint
            then please contact us at:
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginBottom: "7.5pt",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            Email: contactus@circuland.co.uk
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            Circuland Ltd
            <br />
            <br />
            &nbsp;71-75 Shelton Street,&nbsp;
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            Covent Garden,&nbsp;
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            London,&nbsp;
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            United Kingdom,&nbsp;
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            WC2H 9JQ
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 15,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
              background: "white",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            marginBottom: "7.5pt",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            You can contact the ICO for advice or to make a complaint at{" "}
            <a href="https://ico.org.uk/" target="_blank">
              <span style={{ color: "#7F7F7F" }}>www.ico.org.uk</span>
            </a>
          </span>
        </p>
      </>
    );
  };

  return (
    <div id="PrivacyPolicy" className="section-container">
      {/* SECTION - Page will span 100% width and min-height 100vh and represent 1 page within the UI - the background color and image will be changeable and applied here */}
      <div className="page">
        {/* SECTION - page-underlay will act as the width 1170px container to keep the contents inside. */}
        <div
          id="privacyPolicyUnderlay"
          className="page-underlay"
          style={{
            alignItems: "flex-start",
          }}
        >
          <a href="/">Back to Circuland Website</a>
          {/* <h1> Privacy Policy</h1> */}
          {policyText()}
          {/* <p style={{color: 'lightgrey'}}>Last Updated: 3rd October 2022</p> */}
          <hr style={{ background: "lightgrey" }} />
          {cookiePolicy()}
          <hr style={{ background: "lightgrey", width: "75%" }} />
          <p style={{ color: "lightgrey" }}>Version 2.0</p>
          {/* <p style={{color: 'lightgrey'}}>Last Updated: 3rd October 2022</p> */}
          {/* NOTE V1.0 - Initial Privacy Policy */}
          <p style={{ color: "lightgrey" }}>Last Updated: 13th January 2024</p>
          {/* NOTE V2.0 - Added Microsoft Clarity, Microsoft Clarity Privacy and Google Analytics Privacy Statement. */}
        </div>
      </div>
    </div>
  );
}
