export default function TermsAndConditions() {
  const TermsAndConditionsText = () => {
    return (
      <>
        <h3
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 18,
            fontFamily: '"Times New Roman",serif',
            fontWeight: "bold",
            marginTop: "15.0pt",
            marginBottom: "7.5pt",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 24,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
              fontWeight: "normal",
            }}
          >
            Terms &amp; conditions
          </span>
        </h3>
        <h4
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            fontWeight: "bold",
            marginTop: "7.5pt",
            marginBottom: "7.5pt",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 18,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
              fontWeight: "normal",
            }}
          >
            Definitions
          </span>
        </h4>
        <h5
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 13,
            fontFamily: '"Times New Roman",serif',
            fontWeight: "bold",
            marginTop: "7.5pt",
            marginBottom: "7.5pt",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
              fontWeight: "normal",
            }}
          >
            Parties
          </span>
        </h5>
        <p
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            margin: "0cm",
            marginTop: "0cm",
            marginBottom: "7.5pt",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            "You" and "Your" refer to you, as a User or Third Party of the Site. A "User" is an individual who accesses, browses,
            crawls, scrapes, or in any way uses the Site. A "Third Party" is an individual and/or organisation who accesses,
            browses, crawls, scrapes, or in any way uses the Site. "We", "Us", and "Our" refer to Circuland Ltd.
          </span>
        </p>
        <h5
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 13,
            fontFamily: '"Times New Roman",serif',
            fontWeight: "bold",
            marginTop: "7.5pt",
            marginBottom: "7.5pt",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
              fontWeight: "normal",
            }}
          >
            Site
          </span>
        </h5>
        <p
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            margin: "0cm",
            marginTop: "0cm",
            marginBottom: "7.5pt",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            Unless otherwise specified, the terms "Site", "Website" refer to the pages hosted by Circuland Ltd at
            www.circuland.co.uk and subdomains, and "Circuland" refers to the infrastructure of the Site, the services provided by
            Circuland Ltd, primarily through the Site, and the Circuland brand.
          </span>
        </p>
        <h5
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 13,
            fontFamily: '"Times New Roman",serif',
            fontWeight: "bold",
            marginTop: "7.5pt",
            marginBottom: "7.5pt",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
              fontWeight: "normal",
            }}
          >
            Content
          </span>
        </h5>
        <p
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            margin: "0cm",
            marginTop: "0cm",
            marginBottom: "7.5pt",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            "Content" means text, images, photos, audio, video, location data, and all other forms of data or communication.
            <br />
            <br />
            "Your Content" means Content that You submit or transmit to, through, or in connection with the Site, such as Material
            Passport information, messages, and information that you publicly display or displayed in your account profile.
            <br />
            <br />
            "User Content" means Content that users submit or transmit to, through, or in connection with the Site.
            <br />
            <br />
            "Circuland Content" means Content that we create and make available in connection with the Site.
            <br />
            <br />
            "Third Party Content" means Content that originates from parties other than Circuland Ltd or its users, which is made
            available in connection with the Site.
            <br />
            <br />
            "Site Content" means all of the Content that is made available in connection with the Site, including Your Content,
            User Content, Third Party Content, and Circuland Content.
          </span>
        </p>
        <h4
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            fontWeight: "bold",
            marginTop: "7.5pt",
            marginBottom: "7.5pt",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 18,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
              fontWeight: "normal",
            }}
          >
            Terms of Use
          </span>
        </h4>
        <p
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            margin: "0cm",
            marginTop: "0cm",
            marginBottom: "7.5pt",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            Circuland is owned and operated by Circuland Ltd, registered in England and Wales with company number{" "}
            <span style={{ background: "white" }}>14353001</span>
          </span>
          . Please address all correspondence to:
        </p>
        <p
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            margin: "0cm",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            Circuland Ltd
            <br />
            <br />
          </span>
          <span
            style={{
              fontSize: 15,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
              background: "white",
            }}
          >
            71-75 Shelton Street,
          </span>
        </p>
        <p
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            margin: "0cm",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 15,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
              background: "white",
            }}
          >
            Covent Garden,
          </span>
        </p>
        <p
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            margin: "0cm",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 15,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
              background: "white",
            }}
          >
            London,
          </span>
        </p>
        <p
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            margin: "0cm",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 15,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
              background: "white",
            }}
          >
            United Kingdom,
          </span>
        </p>
        <p
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            margin: "0cm",
            marginTop: "0cm",
            marginBottom: "7.5pt",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 15,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
              background: "white",
            }}
          >
            WC2H 9JQ
          </span>
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          ></span>
        </p>
        <p
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            margin: "0cm",
            marginTop: "0cm",
            marginBottom: "7.5pt",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            By using this site, you agree to be bound by these Terms of Use, which fall within the exclusive jurisdiction of
            English courts in the case of any dispute.
            <br />
            <br />
            We collect anonymous data relating to how you use the site, for example which pages you visit, how long you look at
            them and details of what browser you are using. We can't trace any personal information through this tracking, which
            is covered by our Privacy Policy
            <br />
            <br />
            You agree that in your use of this site:
          </span>
        </p>
        <ul>
          <li>
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Tahoma",sans-serif',
                color: "#7F7F7F",
              }}
            >
              <span style={{ color: "rgb(127, 127, 127)" }}>
                any information you provide on this site is accurate and reliable to the best of your knowledge (this includes all
                personal information, contact details and information related to Material Passports);
              </span>
            </span>
          </li>
          <li>
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Tahoma",sans-serif',
                color: "#7F7F7F",
              }}
            >
              <span style={{ color: "rgb(127, 127, 127)" }}>
                you will not use Circuland to distribute any illegal, obscene or otherwise harmful material;
              </span>
            </span>
          </li>
          <li>
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Tahoma",sans-serif',
                color: "#7F7F7F",
              }}
            >
              <span style={{ color: "rgb(127, 127, 127)" }}>
                you will not do anything to interfere with other users' access to the site;
              </span>
            </span>
          </li>
          <li>
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Tahoma",sans-serif',
                color: "#7F7F7F",
              }}
            >
              <span style={{ color: "rgb(127, 127, 127)" }}>
                you will not copy material from the Website and/or Webapp without our permission.
              </span>
            </span>
          </li>
        </ul>
        <h4
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            fontWeight: "bold",
            marginTop: "7.5pt",
            marginBottom: "7.5pt",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 18,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
              fontWeight: "normal",
            }}
          >
            Risk and Information Quality
          </span>
        </h4>
        <p
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            margin: "0cm",
            marginTop: "0cm",
            marginBottom: "7.5pt",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            Circuland Ltd. provides no absolute guarantees of the accuracy of any information supplied on this Website and/or
            Webapp. Material supplied on this site, including content on our social media accounts, does not constitute legal
            advice and is supplied for the purposes of information only.
          </span>
        </p>
        <h4
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            fontWeight: "bold",
            marginTop: "7.5pt",
            marginBottom: "7.5pt",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 18,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
              fontWeight: "normal",
            }}
          >
            Prohibited Activities
          </span>
        </h4>
        <p
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            margin: "0cm",
            marginTop: "0cm",
            marginBottom: "7.5pt",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            The content and information on this Site (including, but not limited to, messages, data, information, text, photos,
            graphics, maps, icons, software, code or other material), as well as the infrastructure used to provide such content
            and information, is proprietary to us. By using the Website and/or Webapp you agree not to otherwise modify, copy,
            distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell
            or re-sell any information, software, products, or services obtained from or through the Website and/or Webapp.
            Additionally, you agree not to:
          </span>
        </p>
        <ul>
          <li>
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Tahoma",sans-serif',
                color: "#7F7F7F",
              }}
            >
              <span style={{ color: "rgb(127, 127, 127)" }}>
                access, monitor or copy any content or information of the Circuland Website and/or Webapp using any robot, spider,
                scraper or other automated means or any manual process for any purpose without our express written permission;
              </span>
            </span>
          </li>
          <li>
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Tahoma",sans-serif',
                color: "#7F7F7F",
              }}
            >
              <span style={{ color: "rgb(127, 127, 127)" }}>
                violate the restrictions in any robot exclusion headers on the Circuland Website and/or Webapp or bypass or
                circumvent other measures employed to prevent or limit access to this Website;
              </span>
            </span>
          </li>
          <li>
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Tahoma",sans-serif',
                color: "#7F7F7F",
              }}
            >
              <span style={{ color: "rgb(127, 127, 127)" }}>
                take any action that imposes, or may impose, in our discretion, an unreasonable or disproportionately large load
                on our infrastructure;
              </span>
            </span>
          </li>
          <li>
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Tahoma",sans-serif',
                color: "#7F7F7F",
              }}
            >
              <span style={{ color: "rgb(127, 127, 127)" }}>
                deep-link to any portion of this Website for any purpose without our express written permission; or
              </span>
            </span>
          </li>
          <li>
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Tahoma",sans-serif',
                color: "#7F7F7F",
              }}
            >
              <span style={{ color: "rgb(127, 127, 127)" }}>
                "frame", "mirror" or otherwise incorporate any part of this Website into any other website without our prior
                written authorisation.
              </span>
            </span>
          </li>
          <li>
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Tahoma",sans-serif',
                color: "#7F7F7F",
              }}
            >
              <span style={{ color: "rgb(127, 127, 127)" }}>
                attempt to modify, translate, adapt, edit, decompile, disassemble, or reverse engineer any software programs used
                by Circuland in connection with the Website or the services we provide.
              </span>
            </span>
          </li>
        </ul>
        <h4
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            fontWeight: "bold",
            marginTop: "7.5pt",
            marginBottom: "7.5pt",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 18,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
              fontWeight: "normal",
            }}
          >
            Copyright and Trademark Notices
          </span>
        </h4>
        <p
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            margin: "0cm",
            marginTop: "0cm",
            marginBottom: "7.5pt",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            All contents of this Website are: © 2022 Circuland Ltd. All rights reserved. Circuland is not responsible for content
            on websites operated by parties other than Circuland. Circuland is a registered and/or common law trademark of
            Circuland Ltd and may not be copied, imitated or used, in whole or in part, without the prior written permission of
            Circuland. The look and feel of the Site, including all page headers, custom graphics, button icons and scripts, is
            the service mark, trademark and/or trade dress of Circuland and may not be copied, imitated or used, in whole or in
            part, without the prior written permission of Circuland. All trademarks, registered trademarks, product names and
            company names or logos mentioned in the Site are the property of their respective owners, and who by using the site
            provide permission for Circuland to use their name and logo. Reference to any products, services, processes, or other
            information, by trade name, trademark, manufacturer, supplier or otherwise does not constitute or imply endorsement,
            sponsorship or recommendation thereof by Circuland.
          </span>
        </p>
        <h4
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            fontWeight: "bold",
            marginTop: "7.5pt",
            marginBottom: "7.5pt",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 18,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
              fontWeight: "normal",
            }}
          >
            Notice and Take-Down Policy for illegal content
          </span>
        </h4>
        <p
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            margin: "0cm",
            marginTop: "0cm",
            marginBottom: "7.5pt",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            Circuland operates on a "notice and takedown" basis. If you have any complaints or objections to material or content
            posted on the Circuland Website and/or Webapp, or if you believe that material or content posted on the Circuland
            Website and/or Webapp infringes a copyright that you hold, please contact us immediately. We will make all reasonable
            endeavours to remove illegal content within a reasonable time.
          </span>
        </p>
        <h4
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            fontWeight: "bold",
            marginTop: "7.5pt",
            marginBottom: "7.5pt",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 18,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
              fontWeight: "normal",
            }}
          >
            Modifications
          </span>
        </h4>
        <p
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            margin: "0cm",
            marginTop: "0cm",
            marginBottom: "7.5pt",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            We may change, add or delete these Terms and Conditions or any portion thereof from time to time at our sole
            discretion for legal, general regulatory and technical purposes, or due to changes in the services provided or nature
            or layout of the Circuland Website and/or Webapp. Thereafter, you expressly agree to be bound by any such amended
            Terms and Conditions.
          </span>
        </p>
        <p
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            margin: "0cm",
            marginTop: "0cm",
            marginBottom: "7.5pt",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            We may change, suspend or discontinue any aspect of the Circuland service at any time, including availability of any
            feature, database or content. We may also impose limits on certain features and services or restrict your access to
            all or parts of the Circuland Website and/or Webapp or any other Circuland Ltd website without notice or liability for
            technical or security reasons, to prevent against unauthorised access, loss of, or destruction of data or where we
            consider in our sole discretion that you are in breach of any provision of these Terms and Conditions or of any law or
            regulation and where it decides to discontinue providing a service. Your continued use of Circuland now, or following
            the posting of any such notice of any changes, will indicate your acceptance of such modifications.
          </span>
        </p>
        <h4
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            fontWeight: "bold",
            marginTop: "7.5pt",
            marginBottom: "7.5pt",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 18,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
              fontWeight: "normal",
            }}
          >
            Indemnity
          </span>
        </h4>
        <p
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            margin: "0cm",
            marginTop: "0cm",
            marginBottom: "7.5pt",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            By using the site, you agree to defend and indemnify Circuland Ltd and its affiliates and any of their officers,
            directors, employees from and against any claims, causes of action, demands, recoveries, losses, damages, fines,
            penalties or other costs or expenses of any kind or nature including but not limited to reasonable legal and
            accounting fees, brought by third parties as a result of:
          </span>
        </p>
        <ul>
          <li>
            <span className="fr-close-ul">
              <span
                style={{
                  fontFamily: '"Tahoma",sans-serif',
                  fontSize: "10.5pt",
                  color: "#7F7F7F",
                }}
              >
                Your breach of this Agreement or the documents referenced herein;
              </span>
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Tahoma",sans-serif',
                fontSize: "10.5pt",
                color: "#7F7F7F",
              }}
            >
              Your violation of any law or the rights of a third party; or
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Tahoma",sans-serif',
                fontSize: "10.5pt",
                color: "#7F7F7F",
              }}
            >
              Your use of the Circuland Website and/or Webapp.
            </span>
          </li>
        </ul>
        <h4
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            fontWeight: "bold",
            marginTop: "7.5pt",
            marginBottom: "7.5pt",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 18,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
              fontWeight: "normal",
            }}
          >
            Privacy Policy
          </span>
        </h4>
        <p
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            margin: "0cm",
            marginTop: "0cm",
            marginBottom: "7.5pt",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            We believe in protecting your privacy. Any personal information you post on the Site will be used in accordance with
            ourPrivacy Policy.
          </span>
        </p>
        <h4
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            fontWeight: "bold",
            marginTop: "7.5pt",
            marginBottom: "7.5pt",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: 18,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
              fontWeight: "normal",
            }}
          >
            User-generated Content
          </span>
        </h4>
        <p
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            margin: "0cm",
            marginTop: "0cm",
            marginBottom: "7.5pt",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            Please be aware that by submitting content to this Website by electronic mail, postings on this Website or otherwise,
            including any questions, comments, reports of Material Passports, suggestions, ideas or the like contained in any
            submissions (collectively, “Submissions”), you grant Circuland Ltd and its affiliates a nonexclusive, royalty-free,
            perpetual, transferable, irrevocable and fully sublicensable right to (a) use, reproduce, modify, adapt, translate,
            distribute, publish, create derivative works from and publicly display and perform such Submissions throughout the
            world in any media, now known or hereafter devised; and (b) use the name that you submit in connection with such
            Submission. You acknowledge that Circuland may choose to provide attribution of your comments or reviews at our
            discretion. You further grant Circuland the right to pursue at law any person or entity that violates your or
            Circuland’s rights in the Submissions by a breach of this Agreement. You acknowledge and agree that Submissions are
            non-confidential and nonproprietary.
          </span>
        </p>
        <p
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            margin: "0cm",
            marginTop: "0cm",
            marginBottom: "7.5pt",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            You expressly agree not to post, upload to, transmit, distribute, store, create or otherwise publish through the Site
            any of the following:
          </span>
        </p>
        <ul>
          <li>
            <span className="fr-close-ul">
              <span
                style={{
                  fontFamily: '"Tahoma",sans-serif',
                  fontSize: "10.5pt",
                  color: "#7F7F7F",
                }}
              >
                Content that impersonates any person, entity or organisation or otherwise misrepresents your affiliation with a
                person or entity, including Circuland;
              </span>
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Tahoma",sans-serif',
                fontSize: "10.5pt",
                color: "#7F7F7F",
              }}
            >
              Any message, data, information, text, music, sound, photos, graphics, code or any other material that is false,
              unlawful, misleading, libellous, defamatory, obscene, pornographic, indecent, lewd, suggestive, harassing or
              advocates harassment of another person, threatening, invasive of privacy or publicity rights, abusive, inflammatory,
              fraudulent or otherwise objectionable;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Tahoma",sans-serif',
                fontSize: "10.5pt",
                color: "#7F7F7F",
              }}
            >
              Content that is patently offensive, such as content that promotes racism, bigotry, hatred or physical harm of any
              kind against any group or individual;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Tahoma",sans-serif',
                fontSize: "10.5pt",
                color: "#7F7F7F",
              }}
            >
              Content that would constitute, encourage, promote or provide instructions for a conduct of an illegal activity,
              criminal offence, give rise to civil liability, violate the rights of any party in any country in the world;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Tahoma",sans-serif',
                fontSize: "10.5pt",
                color: "#7F7F7F",
              }}
            >
              Content that may infringe any patent, trademark, trade secret, copyright or other intellectual or proprietary right
              of any party. In particular, content that promotes an illegal or unauthorised copy of another person's copyrighted
              work, such as providing pirated computer programs or links to them, providing information to circumvent
              manufacture-installed copy-protect devices, or providing pirated music or links to pirated music files;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Tahoma",sans-serif',
                fontSize: "10.5pt",
                color: "#7F7F7F",
              }}
            >
              Unsolicited promotions, mass mailings or “spamming”, transmission of "junk mail", "chain letters," political
              campaigning, advertising, contests, raffles, or solicitations;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Tahoma",sans-serif',
                fontSize: "10.5pt",
                color: "#7F7F7F",
              }}
            >
              Content containing commercial activities and/or sales without our prior written consent such as contests,
              sweepstakes, barter, advertising, and pyramid schemes;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Tahoma",sans-serif',
                fontSize: "10.5pt",
                color: "#7F7F7F",
              }}
            >
              Content that provides instructional information about illegal activities such as making or buying illegal weapons,
              violating someone's privacy, or providing or creating computer viruses;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Tahoma",sans-serif',
                fontSize: "10.5pt",
                color: "#7F7F7F",
              }}
            >
              Private information of any third party, including, without limitation, surname (family name), addresses, phone
              numbers, email addresses, national insurance numbers and credit card numbers.
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Tahoma",sans-serif',
                fontSize: "10.5pt",
                color: "#7F7F7F",
              }}
            >
              Contains restricted or password only access pages, or hidden pages or images (those not linked to or from another
              accessible page);
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Tahoma",sans-serif',
                fontSize: "10.5pt",
                color: "#7F7F7F",
              }}
            >
              Viruses, corrupted data or other harmful, disruptive or destructive files;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Tahoma",sans-serif',
                fontSize: "10.5pt",
                color: "#7F7F7F",
              }}
            >
              Content that is unrelated to the topic of the property address for which a review is added; or
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Tahoma",sans-serif',
                fontSize: "10.5pt",
                color: "#7F7F7F",
              }}
            >
              Content or links to content that, in the sole judgment of Us, (a) violates the previous subsections herein, (b) is
              objectionable, (c) which restricts or inhibits any other person from using or enjoying the Site, or (d) which may
              expose Us or Our affiliates or users to any harm or liability of any type.
            </span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            margin: "0cm",
            marginTop: "0cm",
            marginBottom: "7.5pt",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            Circuland Ltd takes no responsibility and assumes no liability for any Content posted, stored or uploaded by you or
            any third party, or for any loss or damage thereto, nor are We liable for any mistakes, defamation, slander, libel,
            omissions, falsehoods, obscenity, pornography or profanity you may encounter. As a provider of interactive services,
            We are not liable for any statements, representations or Content provided by its users in any public forum, personal
            home page or other Interactive Area. Although We have no obligation to screen, edit or monitor any of the Content
            posted to or distributed through any Interactive Area, We reserve the right, and have absolute discretion, to remove,
            screen or edit without notice any Content posted or stored on the Site at any time and for any reason, and you are
            solely responsible for creating backup copies of and replacing any Content you post or store on the Site at your sole
            cost and expense.
            <br />
            <br />
            If it is determined that you retain moral rights (including rights of attribution or integrity) in the Content, you
            hereby declare that (a) you do not require that any personally identifying information be used in connection with the
            Content, or any derivative works of or upgrades or updates thereto; (b) you have no objection to the publication, use,
            modification, deletion and exploitation of the Content by Circuland or its licensees, successors and assigns; (c) you
            forever waive and agree not to claim or assert any entitlement to any and all moral rights of an author in any of the
            Content; and (d) you forever release Circuland, and its licensees, successors and assigns, from any claims that you
            could otherwise assert against Circuland by virtue of any such moral rights.
            <br />
            <br />
            Any use of the Interactive Areas or other portions of the Site in violation of the foregoing violates these Site Terms
            and may result in, among other things, termination or suspension of your rights to use the Interactive Areas and/or
            the Site. governmental requests and/or court orders, to protect Circuland ‘s systems and customers, or to ensure the
            integrity and operation of Circuland ‘s business and systems, We may access and disclose any information it considers
            necessary or appropriate, including, without limitation, User information (i.e. name, email address, etc.), IP
            addressing and traffic information, usage history, and posted Content. Our right to disclose any such information
            shall take precedence over any terms of the Privacy Policy.
          </span>
        </p>
        <h4
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            fontWeight: "bold",
            marginTop: "7.5pt",
            marginBottom: "7.5pt",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 18,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
              fontWeight: "normal",
            }}
          >
            Liability Disclaimer
          </span>
        </h4>
        <p
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            margin: "0cm",
            marginTop: "0cm",
            marginBottom: "7.5pt",
            background: "white",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            orphans: 2,
            textAlign: "start",
            widows: 2,
            WebkitTextStrokeWidth: 0,
            textDecorationThickness: "initial",
            textDecorationStyle: "initial",
            textDecorationColor: "initial",
            wordSpacing: 0,
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Tahoma",sans-serif',
              color: "#7F7F7F",
            }}
          >
            THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES PUBLISHED ON THIS WEBSITE MAY INCLUDE INACCURACIES OR ERRORS. WE DO
            NOT GUARANTEE THE ACCURACY OF, AND DISCLAIM ALL LIABILITY FOR ANY ERRORS OR OTHER INACCURACIES RELATING TO THE
            INFORMATION AND DESCRIPTION OF THE MATERIAL PASSPORTS DISPLAYED ON THIS WEBSITE AND/OR CIRCULAND WEBAPP. CIRCULAND LTD
            MAKES NO REPRESENTATIONS OF ANY KIND ABOUT THE SUITABILITY OF THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES
            CONTAINED ON THIS WEBSITE/WEBAPP (SITE CONTENT) OR ANY PORTION THEREOF FOR ANY PURPOSE, AND THE INCLUSION OR OFFERING
            OF ANY PRODUCTS OR SERVICES ON THIS WEBSITE/WEBAPP DOES NOT CONSTITUTE ANY ENDORSEMENT OR RECOMMENDATION OF SUCH
            PRODUCTS OR SERVICES BY CIRCULAND LTD. ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES ARE PROVIDED "AS IS"
            WITHOUT WARRANTY OF ANY KIND. CIRCULAND LTD DISCLAIM ALL WARRANTIES AND CONDITIONS THAT THIS WEBSITE, ITS SERVERS OR
            ANY EMAIL SENT FROM THE CIRCULAND LTD, ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. TO THE MAXIMUM EXTENT
            PERMITTED UNDER APPLICABLE LAW CIRCULAND HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS
            INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES, INCLUDING ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NONINFRINGEMENT.
            <br />
            <br />
            CIRCULAND ALSO EXPRESSLY DISCLAIMS ANY WARRANTY OR REPRESENTATION AS TO THE ACCURACY OR PROPRIETARY CHARACTER OF THE
            SITE CONTENT. NOTHING IN THIS AGREEMENT SHALL EXCLUDE OR LIMIT CIRCULAND’S LIABILITY FOR (I) DEATH OR PERSONAL INJURY
            CAUSED BY NEGLIGENCE; (II) FRAUD; OR (III) WILFUL DEFAULT OR GROSS NEGLIGENCE (IV) ANY OTHER LIABILITY WHICH CANNOT BE
            EXCLUDED UNDER APPLICABLE LAW.
            <br />
            <br />
            SUBJECT TO THE FOREGOING, YOU USE THE SITE AT YOUR OWN RISK AND IN NO EVENT SHALL CIRCULAND LTD (ITS OFFICERS,
            DIRECTORS AND AFFILIATES) BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL LOSSES
            OR DAMAGES OR ANY LOSS OF INCOME, PROFITS, GOODWILL, DATA, CONTRACTS, USE OF MONEY, OR LOSS OR DAMAGES ARISING FROM OR
            CONNECTED IN ANY WAY TO BUSINESS INTERRUPTION OF ANY TYPE ARISING OUT OF, OR IN ANY WAY CONNECTED WITH, YOUR ACCESS
            TO, DISPLAY OF OR USE OF THIS WEBSITE OR WITH THE DELAY OR INABILITY TO ACCESS, DISPLAY OR USE THIS WEBSITE AND/OR
            WEBAPP (INCLUDING, BUT NOT LIMITED TO ANY COMPUTER VIRUSES, INFORMATION, SOFTWARE, LINKED SITES, PRODUCTS, AND
            SERVICES OBTAINED THROUGH THIS WEBSITE; OR OTHERWISE ARISING OUT OF THE ACCESS TO, DISPLAY OF OR USE OF THIS WEBSITE)
            WHETHER BASED ON A THEORY OF NEGLIGENCE, CONTRACT, TORT, STRICT LIABILITY, OR OTHERWISE, AND EVEN IF CIRCULAND LTD HAS
            BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            <br />
            <br />
            THESE TERMS AND CONDITIONS AND FOREGOING LIABILITY DISCLAIMER, DO NOT AFFECT MANDATORY LEGAL RIGHTS THAT CANNOT BE
            EXCLUDED UNDER APPLICABLE LAW.
            <br />
            <br />
            The limitation of liability reflects the allocation of risk between the parties. The limitations specified in this
            section will survive and apply even if any limited remedy specified in these terms is found to have failed of its
            essential purpose. The limitations of liability provided in these terms inure to the benefit of Circuland Ltd.
          </span>
        </p>
        <p
          style={{
            marginRight: "0cm",
            marginLeft: "0cm",
            fontSize: 16,
            fontFamily: '"Times New Roman",serif',
            margin: "0cm",
          }}
        >
          <span style={{ fontFamily: '"Tahoma",sans-serif', color: "#7F7F7F" }}></span>
        </p>
      </>
    );
  };

  return (
    <div id="PrivacyPolicy" className="section-container">
      {/* SECTION - Page will span 100% width and min-height 100vh and represent 1 page within the UI - the background color and image will be changeable and applied here */}
      <div className="page">
        {/* SECTION - page-underlay will act as the width 1170px container to keep the contents inside. */}
        <div
          id="privacyPolicyUnderlay"
          className="page-underlay"
          style={{
            alignItems: "flex-start",
          }}
        >
          <a href="/">Back to Circuland Website</a>
          {TermsAndConditionsText()}
          <hr style={{ background: "lightgrey", width: "75%" }} />
          <p style={{ color: "lightgrey" }}>Last Updated: 3rd October 2022</p>
        </div>
      </div>
    </div>
  );
}
