import { useState, useEffect, useLayoutEffect } from "react";
import App from "./App";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { Routes, Route } from "react-router-dom";
import { initGA } from "./controller/ga-utils.ts";
import { Cookies } from "react-cookie-consent";
import Modal from "react-modal";
import Switch from "react-switch";
import TermsAndConditions from "./pages/TermsAndConditions";
import { useSelector, useDispatch } from "react-redux";
import { set } from "./redux/generalUtils";
import { clarity } from "clarity-js";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    minWidth: "25%",
    maxWidth: "300px",
  },
};

const mobileViewStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    maxHeight: "85%",
    width: "85%",
  },
};

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

Modal.setAppElement(document.getElementById("consentModal"));

export default function Website() {
  const dispatch = useDispatch();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [savePreferences, setsavePreferences] = useState(true);
  const [size, setSize] = useState([0, 0]);

  const generalUtils_window = useSelector((state) => state.generelUtils.value);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);

      if (generalUtils_window[0] !== window.innerWidth || generalUtils_window[1] !== window.innerHeight) {
        dispatch(set([window.innerWidth, window.innerHeight]));
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    const isConsent = getCookie("isConsent");
    if (isConsent === undefined) setIsOpen(true);
    if (isConsent === "true") handleAcceptCookie();
  }, []);

  const handleAcceptCookie = () => {
    if (savePreferences) setCookie("isConsent", true, 300);
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      switch (process.env.NODE_ENV) {
        case "production":
          initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);

          // NOTE - Helpful for setup: 'https://github.com/microsoft/clarity/issues/165'
          clarity.start({
            projectId: "klfgyrl6xe",
            upload: "https://m.clarity.ms/collect",
            track: true,
            content: true,
          });

          break;
        case "development":
          initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID_DEVEN);

          clarity.start({
            projectId: "kl84vvqeiv",
            upload: "https://m.clarity.ms/collect",
            track: true,
            content: true,
          });
          break;
        default:
          break;
      }
    }
    setIsOpen(false);
  };

  const handleDeclineCookie = () => {
    if (savePreferences) setCookie("isConsent", false, 30);
    //remove google analytics cookies (in case they were mistakenly added or previously accepted.)
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
    clarity.stop();
    setIsOpen(false);
  };

  return (
    <>
      <Routes>
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
        <Route path="/*" element={<App />} />
      </Routes>
      <div>
        <Modal id="consentModal" isOpen={modalIsOpen} style={size[0] < 600 ? mobileViewStyle : customStyles} contentLabel="Consent For Cookies" shouldCloseOnOverlayClick={false}>
          <div className="consentImageContainerFlex">
            <img src="https://circuland-website-images.s3.eu-west-2.amazonaws.com/cookiesImage.png" className="cookiesImage" alt="" />
          </div>
          <div className="consentTextContainerFlex">
            <h4> We use cookies </h4>
            <br />
            <p className="ptext" style={{ fontSize: "12pt !important" }}>
              We use cookies and other tracking technologies to improve your browsing experience on our website, to show you personalized content and targeted ads, to analyse our
              website traffic, and to understand where our visitors are coming from.
            </p>
            <br />
            <br />
            <label className="savePreferences">
              <span style={{ marginRight: "10px", fontSize: "12pt" }} className="ptext">
                Remember my preferences
              </span>
              <Switch
                onChange={(e) => {
                  setsavePreferences(!savePreferences);
                }}
                checked={savePreferences}
              />
            </label>
            <br />
          </div>
          <div className="consentButtonContainerFlex">
            <button type="button" className="consentNoButton btn" onClick={handleDeclineCookie}>
              I Decline
            </button>
            <button type="button" className="consentYesButton btn btn-success" onClick={handleAcceptCookie}>
              I Accept
            </button>
          </div>
        </Modal>
      </div>
    </>
  );
}
